import axios from "axios";
import qs from "qs";
import { Dialog, Toast } from "vant";
let requestCount = 0; // 当前未完成的请求数量，便于后面用来关闭loading
let switchToLoginPageFlag = false; // 防止重复弹窗
let requestLoadingArr = [];
Toast.allowMultiple(); // 允许同时存在多个 Toast

const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,

  // 超时
  timeout: 500000,
  paramsSerializer: (params) => {
    // 序列化GET请求防止[]解析为数组导致接口报错
    return qs.stringify(params, { indices: false });
  },
});

// 在请求或响应被 then 或 catch 处理前拦截它们。
// http request 拦截器 在headers上添加token
// 添加请求拦截器
service.interceptors.request.use(
  (config) => {
    if (process.env.NODE_ENV === "test") {
      console.log("config==", config);
    }
    // 在发送请求之前做些什么
    // if (!["/operate/data/listByTypeEns"].includes(config.url)) {
    // config.headers["Authorization"] = "";
    // }
    if (config.apiOtherConf) {
      config.headers["Content-Type"] =
        config.apiOtherConf["Content-Type"] || "application/json;charset=UTF-8";
    } else {
      config.headers["Content-Type"] = "application/json;charset=UTF-8";
    }
    beforeSend();
    return config;
  },
  (err) => {
    // 对请求错误做些什么
    return Promise.reject(err);
  }
);

/**
 * 请求前显示Loading
 */
function beforeSend(loaderText) {
  // 累计当前未完成请求数，便于后面判断当前所有请求是否已完成

  requestCount = requestCount + 1;

  // 一次请求多个接口，显示第一个loading
  if (requestCount <= 1) {
    const toastLoading = Toast.loading({
      message: loaderText || "加载中...",
      forbidClick: true, // 禁用背景点击
      duration: 0,
    });
    requestLoadingArr.push(toastLoading);
  }
}

// http response 拦截器  token 已过期，重定向到登录页面
// 添加响应拦截器
service.interceptors.response.use(
  (response) => {
    if (process.env.NODE_ENV === "test") {
      console.log("response.data=", response.data);
    }
    handleComplete();
    const { code = 200, msg } = response.data;
    if (code === 401) {
      if (!switchToLoginPageFlag) {
        switchToLoginPageFlag = true;
        showErrorMessage("登录已过期，请重新登录", {
          resCode: code,
        });
      }
    } else if (code === 200) {
      return response.data;
    } else {
      showErrorMessage(msg);
    }
    return response.data;
  },
  (error) => {
		console.log("err===", error);
    handleComplete();
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "数据请求超时";
    } else if (message.includes("Request failed with status code")) {
      const errNum = message.substr(message.length - 3);
      if (errNum === "502") {
        message = "正在发布新版本，请稍后再试";
      } else {
        message = "系统接口" + errNum + "异常";
      }
    }
    showErrorMessage(message);
    return error; // 返回接口返回的错误信息
  }
);

/**
 * 请求完成时的回调，隐藏loading
 */
function handleComplete() {
  requestCount = requestCount - 1;
  // 在所有请求完成时，才隐藏loading
  if (requestCount <= 0) {
    requestCount = 0;
    requestLoadingArr.forEach((ele) => {
      ele?.clear && ele.clear();
    });
  }
}

/**
 * 弹窗显示错误信息
 */
function showErrorMessage(errorMessage, mObj) {
  const { resCode } = mObj || {};
  Dialog.alert({
    message: errorMessage || "系统未知错误，请反馈给管理员",
    confirmButtonColor: "#5094FF",
  }).then(() => {
    if (resCode === 401) {
      switchToLoginPageFlag = false;
      // wx.reLaunch({
      //   url: '/pages/login/login'
      // })
    }
  });
}

export default service;
