/* eslint-disable no-prototype-builtins */
import store from "@/store";
import { Dialog, Toast } from "vant";

export const formatTime = (
  date = new Date(),
  format = "-",
  dateType = "datetime"
) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  // iOS 有些版本不支持以中划线分隔的日期格式，正确写法是 new Date('2020/01/01')
  if (dateType === "date") {
    return `${[year, month, day].map(formatNumber).join(format)}`;
  }
  return `${[year, month, day].map(formatNumber).join(format)} ${[
    hour,
    minute,
    second,
  ]
    .map(formatNumber)
    .join(":")}`;
};

export const formatNumber = (n) => {
  n = n.toString(); // 转字符串, 用于初始化时间选择定位
  return n[1] ? n : `0${n}`;
};

/** 时间格式转换，时间参数最好传时间戳，防止兼容问题 */
export const iosDateFormat = (str) => {
  if (str) {
    // iOS 有些版本不支持以中划线分隔的日期格式，正确写法是 new Date('2020/01/01')
    return str.replace(/-/g, "/");
  }
  return str;
};

/** 计算每个月的最大天数 */
export function monthCalcDays(year, month) {
  if (!year || !month) {
    return 31;
  }
  const numMonth = Number(month);
  if (numMonth === 2) {
    if (
      (year % 4 == 0 && year % 100 != 0) ||
      (year % 400 == 0 && year % 4000 != 0)
    ) {
      return 29;
    } else {
      return 28;
    }
  } else {
    if ([4, 6, 9, 11].includes(numMonth)) {
      return 30;
    } else {
      return 31;
    }
  }
}

// 时间 - 截取掉秒后显示到分
export function dateFormatMinute(str) {
  if (str && typeof str === "string") {
    return str.split(":").length > 2
      ? str.split(":").slice(0, 2).join(":")
      : str;
  }
  return str;
}

/** 提示信息 */
export function vanToastModal(str, tObj) {
  if (!str || typeof str !== "string") {
    return;
  }
  const { sTime = 2300, isModalTip, forbidClick } = tObj || {};
  if (str.length >= 21 || isModalTip) {
    Dialog.alert({
      message: str,
      confirmButtonColor: "#5094FF",
    }).then(() => {});
  } else {
    Toast({
      message: str,
      forbidClick, // 禁用背景点击
      duration: sTime,
    });
  }
}

/** 提示弹窗 */
export function vanDialogModal(cObj, cb = () => {}) {
  const { title, message, cancelCallBack } = cObj || {};
  Dialog.confirm({
    title,
    message,
    confirmButtonColor: "#5094FF",
  })
    .then(() => {
      // on confirm
      cb && cb();
    })
    .catch(() => {
      // on cancel
      cancelCallBack && cancelCallBack();
    });
}

// 数值类型转换
export function typeConversion(val, type = "string") {
  if (val === null || val === undefined || val === "") {
    return val;
  }
  if (type === "number") {
    return isNaN(val) ? val : Number(val);
  }
  // typeof val === 'number' && val.toString(),
  return val.toString();
}

// 获取字典词
export function getDictObj(dictKeys, val, keyName = "nameCn") {
  // keyName - 传 '' 返回对象类型
  let dictArr = [];
  if (typeof dictKeys === "string") {
    // 属性名
    dictArr = store.getters.dictData[dictKeys] || [];
  } else {
    dictArr = dictKeys || []; // 属性值
  }

  if ((typeof val === "string" && val.includes(",")) || Array.isArray(val)) {
    // 多个字典词, 如："0,1,2" 或数组
    const vKeys = Array.isArray(val) ? val : val.split(",");
    return vKeys.map((v) => {
      const sDictObj = (dictArr || []).find((t) => t.value === v) || {};
      if (keyName) {
        return sDictObj[keyName];
      }
      return sDictObj;
    });
  }

  const dictObj =
    (dictArr || []).find((t) => t.value === typeConversion(val)) || {};
  if (keyName) {
    return dictObj[keyName]; // 有判断条件，不能设置默认值。需要默认值加传一个参数值
  }
  return dictObj;
}

// 去重
export function duplicateRemoval(arr, key) {
  return arr.filter((i, idx) => {
    return (
      arr.findIndex((j) => {
        return key !== undefined && key !== null ? j[key] === i[key] : j === i;
      }) === idx
    );
  });
}

// 深拷贝
export function deepCopy(obj) {
  let result = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        result[key] = deepCopy(obj[key]); //递归复制
      } else {
        result[key] = obj[key];
      }
    }
  }
  return result;
}
