import Vue from "vue";
import App from "./App.vue";
import fastclick from "fastclick";
import "@/assets/styles/reset.less";
import Vconsole from "vconsole";
import store from "./store";
import router from "./router";
import { getDictObj, iosDateFormat } from "@/utils/util";
// 不要全局引入vant, 一次性导入所有组件会增加代码包体积

import { urlConfig } from "@/api/api-urls.js";
import { checkRepeatedRequest } from "@/api/services.js";

fastclick.attach(document.body);

console.log("process.env==", process.env);
const { NODE_ENV } = process.env;
if (NODE_ENV !== "production") {
  // 生产环境隐藏
  let vConsole = new Vconsole();
  Vue.use(vConsole);
}

Vue.config.productionTip = false;

Vue.prototype.getDictObj = getDictObj; // 获取字典词对象
Vue.prototype.iosDateFormat = iosDateFormat; // IOS时间格式‘-’转换为 ‘/’
Vue.prototype.$urlConfig = urlConfig;
Vue.prototype.$checkRepeatedRequest = checkRepeatedRequest;

const nameArr = (router.options.routes || [])
  .map((e) => e.name)
  .filter((n) => !!n);
// console.log("router==", router.options.routes, store, nameArr);
store.dispatch("keepAlive/addStorePageName", nameArr);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
