<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <keep-alive :include="keepAliveList">
        <router-view class="child-view"></router-view>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "App",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      keepAliveList: (state) => state.keepAlive.keepAliveList,
    }),
  },
};
</script>

<style lang="less" scoped>
.fade-enter {
  opacity: 0;
}
.fade-leave {
  opacity: 1;
}
.fade-enter-active {
  transition: opacity 0.3s;
}
.fade-leave-active {
  opacity: 0;
  transition: opacity 0.3s;
}
</style>
