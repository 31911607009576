import mobileRequest from "./request";
import { Toast } from "vant";

// 接口请求公共方法
export function mobileCommonRequest(
  url,
  data = {},
  method = "post",
  apiOtherConf
) {
  let dataKey = "data";
  if (method === "get") {
    dataKey = "params";
  }
  return mobileRequest({
    method,
    url,
    [dataKey]: data,
    apiOtherConf,
  });
}

export function checkRepeatedRequest(_self, httpConfig, opt = {}) {
  /**
   * 有连续应用此方法时，需要取消延时返回，否则后一个接口会报‘重复请求’
   * 可以在后一个接口调用前 this.preventRepeatedClicks = false 重置状态
   */
  return new Promise((resolve, reject) => {
    if (!httpConfig.isPreventRequest) {
      // 改变请求状态，不阻止重复请求
      _self.preventRepeatedClicks = false;
    }
    if (_self.preventRepeatedClicks) {
      // 防止重复点击请求
      Toast({
        message: "请不要频繁操作，稍后再试",
        forbidClick: true, // 禁用背景点击
        duration: 3000,
      });

      return reject({
        errCode: "check-repeat",
        webMsg: "重复请求",
      });
    }

    if (httpConfig.isPreventRequest) {
      // 是否阻止重复请求，loading已防止重复点击，特殊情况再单独处理
      _self.preventRepeatedClicks = true;
    }
    const {
      apiUrl,
      apiMethod = "post",
      mobileHttpRequest,
      apiOtherConf,
    } = httpConfig || {};

    const comRequest = mobileHttpRequest
      ? mobileHttpRequest(opt)
      : mobileCommonRequest(apiUrl, opt, apiMethod, apiOtherConf);

    comRequest
      .then((res) => {
        if (res.code && [200].includes(Number(res.code))) {
          resolve(res);
        } else {
          reject(res);
        }
      })
      .finally(() => {
        setTimeout(() => {
          // 加延时防止提交后获取接口改变状态前，再次发起请求
          _self.preventRepeatedClicks = false;
        }, 100);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
