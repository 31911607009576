import { mobileCommonRequest } from "../../api/services";
import { urlConfig } from "../../api/api-urls";

export default {
  namespaced: true,
  state: {
    dictStoreData: {},
  },
  mutations: {
    SET_DICTIONARY: (state, data) => {
      state.dictStoreData = {
        ...state.dictStoreData,
        ...data,
      };
    },
  },
  actions: {
    /** 字典词 */
    getDictionary({ commit, state }, paramsField) {
      return new Promise((resolve, reject) => {
        let keyArr = paramsField || [];
        let fieldArr = [];
        keyArr.forEach((key) => {
          // 已有的单个字典词，不重复调接口
          if (!state.dictStoreData[key]) {
            fieldArr.push(key);
          }
        });
        if (!fieldArr.length) {
          return resolve();
        }
        const typeEn = fieldArr.join(",");
        mobileCommonRequest(urlConfig.getBasicArchives, { typeEn }, "get")
          .then((res) => {
            // console.log('字典词-res==', res)
            commit("SET_DICTIONARY", res.data);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};

/**
 * 应用
 * 
 * import { mapActions, mapState } from "vuex";
 * 
 * ...mapActions(["dictionary/getDictionary"]),
 * 
 * this["dictionary/getDictionary"](["status"])
 * 
 * computed: {
    ...mapState({
      dictStoreData: (state) => state.dictionary.dictStoreData,
    }),
  },
 */
