import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history", // 去掉url中的#
  routes: [
    {
      path: "/",
      redirect: "/carPhoneSearch",
    },
    {
      path: "/carPhoneSearch",
      name: "CarPhoneSearch",
      component: (resolve) =>
        require(["@/views/carDispatch/phoneSearch.vue"], resolve),
    },
    {
      path: "/carDispatchList",
      name: "CarDispatchList",
      component: (resolve) =>
        require(["@/views/carDispatch/list.vue"], resolve),
    },
    {
      path: "/carInitQuery",
      name: "CarInitQuery",
      component: (resolve) =>
        require(["@/views/carDispatch/initQuery.vue"], resolve),
    },
    {
      path: "/carCalendar",
      name: "CarCalendar",
      component: (resolve) =>
        require(["@/views/carDispatch/calendar.vue"], resolve),
    },
    {
      path: "/carDetails",
      name: "CarDetails",
      component: (resolve) =>
        require(["@/views/carDispatch/details.vue"], resolve),
    },
    {
      path: "*",
      component: () => import("@/views/error/404.vue"),
    },
  ],
});
