export const urlConfig = {
  // post请求接口
  loginApi: "/login", // 登录
  fileMobileUploadUrl: "/customer/order/attach/unauthorizedUploads", // H5附件上传-不传token
  postDriverTrafficRecord:
    "/driverOrderReservationWarehousing/vehicleTrafficRecord", //车辆通行记录
  postDriverCarSave: "/driverOrderReservationWarehousing", // 新建或更新入仓预约

  // put请求接口
  putDriverCarDepart: "/driverOrderReservationWarehousing/depart", // 预约出发

  // get请求接口
  getBasicArchives: "/operate/data/listByTypeEns", // 字典数据
  getMobileAttachList: "/driverOrderReservationWarehousing/getAttachList", // H5附件查询-不传token
  getDriverCarOrderList: "/driverOrderReservationWarehousing/page", // 入仓预约列表
  getDriverCarDetail: "/driverOrderReservationWarehousing/getInfo", // 预约详情 /{id}
  getDriverCarCode: "/driverOrderReservationWarehousing/getCode", // 获取预约码
  getNoAppointmentInfo: "/driverOrderReservationWarehousing/noAppointment", // 未预约详情 {orderReservationCarIds}

  // DELETE请求接口
  delDriverCarDetail: "/driverOrderReservationWarehousing/del", // 删除入仓预约 /{id}
};
