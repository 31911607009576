import { duplicateRemoval } from "../../utils/util";
export default {
  namespaced: true,
  state: {
    keepAliveList: [], // 缓存页面name值, 首字母大写缓存
  },
  mutations: {
    ADD_PANG_NAME: (state, data) => {
      state.keepAliveList = duplicateRemoval([...state.keepAliveList, ...data]);
    },
    DEL_PANG_NAME(state, list) {
      state.keepAliveList = state.keepAliveList.filter(
        (e) => !list.includes(e)
      );
    },
  },
  actions: {
    addStorePageName({ commit }, pageList) {
      commit("ADD_PANG_NAME", pageList);
    },
    delStorePageName({ commit }, pageList) {
      commit("DEL_PANG_NAME", pageList);
    },
  },
};
