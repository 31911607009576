import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import keepAlive from "./modules/keepAlive";
import dictionary from "./modules/dictionary";
import getters from "./getters";

export default new Vuex.Store({
  modules: {
    dictionary,
    keepAlive,
  },
  getters,
});
